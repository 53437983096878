<template>
    <div class="sweepstakes_wrap">

      <div class="start_rule">
        <h3>下单立减</h3>
        <div class="start_rule_content">
          <p style="margin-top:20px;text-align: center">活动期间购买标物，订购满1000立减200。</p>
        </div>
        <div class="send_bottom">
          <ul class="bottom_product">
            <li v-for="item in productRms" :key="item.id">
              <router-link :to="{ name: 'productdetail', params: { id: item.id, classId: item.classId ? item.classId : item.nclassId} }">
                <img :src="item.pic" alt="">
                <p class="send_pronum">{{item.catno}}</p>
                <p class="send_proname">{{item.cnname}}</p>
              </router-link>
            </li>
          </ul>
        </div>
        <p style="text-align: center">
          <router-link :to="{ name: 'ProductCategory'}"> <span style="font-size: 16px;" class="see_more" href="">查看更多</span></router-link>
        </p>
      </div>

        <div class="start_content">

            <div class="sweepstakes_title">
                <h3>幸运大转盘·iphone14pro等你来拿</h3>
            </div>
            <div class="sweepstakes_content">
                <canvas class="Sweepstakes_dibu" id="myCanvas" width="700" height="550">
                    当前浏览器版本过低，请使用其他浏览器尝试
                </canvas>
                <img class="Sweepstakes_start" src="@/assets/img/lottery/start.png" id="start">
            </div>
              <p style="font-size: 14px;text-align: center;color:#5c1e08">剩余抽奖次数：<span style="color:#ea5146">{{partakeCount}}</span></p>
              <p style="font-size: 14px;text-align: center;color:#5c1e08;margin-top: 10px;line-height: 25px">分享给好友成功注册，还可获得1次抽奖机会，<br/>最多可额外获得3次抽奖机会。</p>
              <p style="text-align: center;height: 35px"><a  style="display: inline-block; background-color: #ff5b21;border-radius:0.96rem;color:#fff;font-size: 16px;width:120px;height: 30px;text-align: center;line-height: 30px" @click="shareFriends">分享好友</a></p>
              <p style="text-align: center;height: 20px"><span style="color:#ea5146;font-size: 14px" @click="getAddress()">中奖记录/收货地址</span></p>
             <span style="font-size: 14px;padding-left: 20px">中奖列表：</span>
             <div class="viewport" style="padding-bottom: 20px">
                <ul class="zhongjiang">
                  <li v-for="item in winnerVOS" :key="item.id">
                    恭喜
                      <span v-if="item.user && item.user.mobile">
                          {{item.user.mobile.substring(0,3)}}****{{item.user.mobile.substring(7)}}
                      </span> 抽到{{item.rewardName}}
                  </li>
                </ul>
             </div>
        </div>



      <div class="start_rule send_products">
        <h3>伟业计量·标准物质研究中心</h3>
        <div class="start_rule_content">
          <p style="margin-top: 10px">北方伟业计量集团，成立于2007年,是国家高新技术企业，标准物质研究中心，中国计量测试学会会员单位。</p>
          <p style="margin-top: 10px">伟业计量拥有2.4万平科研基地，独立研制十三大系列标准物质30000余种，获批国家一级标准物质28种，国家二级标准物质近600种。产品涉及食品、环境、化工、临床医学及药品等多个领域，为国内外数千家检测机构提供专业的检测标准。</p>
          <ul>
            <li><img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/31afe3aa5c0d3e57bc404ba242c2c288.png" alt=""></li>
            <li><img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/b818bbc2dde17ced6bf727f7d0670b36.png" alt=""></li>
            <li><img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/e31253ae7a178b7bffd0f458af7e43f7.png" alt=""></li>
          </ul>
        </div>
      </div>

        <red-prize
          v-if="showRedPrize"
          @close="onClosePrize"
          :src="awardImg"
          :awardName="awardName"
          hint="恭喜您获得"
        ></red-prize>
    </div>

  <!--        弹窗-->
  <div class="rewardadd" id="rewardadd" v-if="showRewardadd">

    <div class="modal-backdrop"></div>

    <div class="modal-body">
      <img src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/faa59b1650dbacb64250045ea371df39.png"
           class="modal-close" @click="closeModal()">
      <div><img style="width: 100%" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/8ea3c33d66fb76a718d95bfbec12df9d.png" alt=""></div>
      <div class="reward_list">
        <table class="prize_record">
          <tr>
            <th style="width:30px">序号</th>
            <th>奖品名称</th>
            <th style="width:74px">中奖时间</th>
            <th style="width:52px">发货状态</th>
          </tr>
          <tr v-for="(winner, index) in winnerList" :key="index">
            <td>{{index+1}}</td>
            <td>{{winner.rewardName}}
                <router-link :to="{ name: 'Intergral' }" style="color:#ff5b21" v-if="winner.rewardType && winner.rewardType=='1'">
                    （积分兑换）
                </router-link>
            </td>
            <td>{{dateFormat(winner.createTime,'yyyy.MM.dd')}}</td>
            <td>
                <template  v-if="winner.rewardType && winner.rewardType=='3'">
                    <sapn v-if="winner.winnerRewardStatus && winner.winnerRewardStatus==2">已发货</sapn>
                    <sapn v-else>待发货</sapn>
                </template>
                <template  v-else>
                    <sapn>/</sapn>
                </template>

            </td>
          </tr>
        </table>
      </div>
      <form id="rewardAddForm">
      </form>

      <div style="width:90%;margin: auto;margin-top: 20px;font-size: 14px;"  v-if="addressInfo&&addressInfo.id">
          <div style="font-size: 15px;color: #333;">
              收货信息
          </div>
          <div style="color: #666;margin-top: 7px;">
              <p style="line-height: 20px;">姓名：{{addressInfo.receiver}}</p>
              <p style="line-height: 20px;">电话：{{addressInfo.contactTel}}</p>
              <p style="line-height: 20px;">地址：{{addressInfo.fullAddress}}</p>
          </div>

      </div>
      <div class="coupon-button">
        <router-link :to="{ name: 'addAddress', query: { id: addressInfo? addressInfo.id : 0}}">
            <span class="coupon_btn" @click="closeModal()" v-if="addressInfo && addressInfo.id">编辑地址</span>
            <span class="coupon_btn" @click="closeModal()" v-else>添加地址</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent, ref, onMounted, inject, nextTick} from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import RedPrize from "./RedPrize.vue";
import { useRouter } from "vue-router";
import qs from "qs";
import dateFormat from "../utils/dateFormat";

export default defineComponent({
    name: "sendGiftOflLuxury",
    components: {
      RedPrize
    },
    setup(){
      const router = useRouter();
      const showRedPrize = ref(false);
      const showRewardadd = ref(false);
      const awardName = ref();
      const awardImg = ref();
      const inviteCount = ref(0);
      const isLoginFlag = ref(0);
      const partakeCount = ref(0);
      const userId = ref(0);
      const winnerVOS = ref([]);
      const productRms = ref([]);
      const myInvitationImg = ref('');

      const winnerList = ref([]);//中奖记录
      const addressInfo = ref(null);//地址信息


      // 获取相关信息
      function getGiftOflLuxuryInfo(eventUniqueMark: any) {
        axios
            .get("/M/Event/getGiftOflLuxuryInfo" , { params: { eventUniqueMark: eventUniqueMark } })
            .then((res): void => {
              const data = res.data.obj;
              inviteCount.value = data.inviteCount;
              isLoginFlag.value = data.isLoginFlag;
              partakeCount.value = data.partakeCount;
              userId.value = data.userId;
              winnerVOS.value = data.winnerVOS;
              productRms.value = data.productRms;

            })
            .catch((err) => {
              console.log(err);
            });
      }

      getGiftOflLuxuryInfo('GIFT_OF_LUXURY');

      //分享好友
      function shareFriends() {
        let confirmText ="确定";
        let optionControl =1;
        let content =  `
                  <div>长按我，转发给朋友</div>
                  <div style="height: 140px;">
                    <div style="text-align: center;width: 98%;">
                      <img width="160px" src="`+myInvitationImg.value+`"  alt="">
                    </div>
                  </div>
                  `;
        if(isLoginFlag.value == 0){
          confirmText="登录";
          optionControl=0;
          content = "登录后即可邀请，立刻前往";

        }
        Modal({
            title: "温馨提示",
            confirmText:confirmText,
            optionControl:optionControl,
            content:content,
            dangerouslyUseHTMLString: true,
            onConfirm: () => {
              router.push({
                name: "InviteRegister",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                    addType:"1",
                },
              });
            },
            onClose: () => {
              console.log('1')
            },
          });
      }

    // 中奖信息
    function getAddress() {
        if(isLoginFlag.value == 0){
            Modal({
                title: "温馨提示",
                content: "请先执行登录",
                confirmText:"登录",
                onConfirm: () => {
                    router.push({
                        name: "InviteRegister",
                        query: {
                            redirect: router.currentRoute.value.fullPath,
                            addType:"1",
                        },
                    });
                },
            });
            return false;
        }

        showRewardadd.value = true;
        axios
            .get("/M/Event/rewardWinnerInfo")
            .then((res): void => {
                const data = res.data.obj;
                addressInfo.value = data.addressInfo;
                winnerList.value = data.winnerList;
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // 关闭弹框
    function closeModal() {
        showRewardadd.value = false;
    }

      ($.fn as any).WheelSurf = function (options: any) {
          const _default = {
              outerCircle: {
                  color: '#df1e15'
              },
              innerCircle: {
                  color: '#f4ad26'
              },
              dots: ['#fbf0a9', '#fbb936'],
              disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
              title: {
                  color: '#5c1e08',
                  font: '19px Arial'
              }
          }

          $.extend(_default,options)
          // 画布中心移动到canvas中心

          /* eslint-disable */
          const _this = this[0],
              width = _this.width,
              height = _this.height,
              ctx = _this.getContext("2d"),
              imgs: any = [],
              awardTitle: any = [],
              awardPic: any = []
          for (const item in (_default as any).list) {
              awardTitle.push((_default as any).list[item].name)
              imgs.push((_default as any).list[item].image)
          }
          const num = imgs.length
          // 圆心
          const x = width / 2
          const y = height / 2
          ctx.translate(x, y)

          return {
              init: function (angelTo: any) {
                  angelTo = angelTo || 0;

                  ctx.clearRect(-this.width, -this.height, this.width, this.height);

                  // 平分角度
                  const angel = (2 * Math.PI / 360) * (360 / num);
                  let startAngel = 2 * Math.PI / 360 * (-90)
                  let endAngel = 2 * Math.PI / 360 * (-90) + angel

                  // 旋转画布
                  ctx.save()
                  ctx.rotate(angelTo * Math.PI / 180);
                  // 画外圆
                  ctx.beginPath();
                  ctx.lineWidth = 25;
                  ctx.strokeStyle = _default.outerCircle.color;
                  ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                  ctx.stroke();
                  // 画里圆
                  ctx.beginPath();
                  ctx.lineWidth = 23;
                  ctx.strokeStyle = _default.innerCircle.color;
                  ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                  ctx.stroke();

                  // 装饰点
                  const dotColor = _default.dots
                  for (let i = 0; i < 12; i++) {
                      // 装饰点 圆心 坐标计算
                      ctx.beginPath();
                      const radius = 230;
                      const xr = radius * Math.cos(startAngel)
                      const yr = radius * Math.sin(startAngel)

                      ctx.fillStyle = dotColor[i % dotColor.length]
                      ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                      ctx.fill()

                      startAngel += (2 * Math.PI / 360) * (360 / 12);

                  }
                  // 画里转盘
                  const colors = _default.disk
                  for (let i = 0; i < num; i++) {
                      ctx.beginPath();
                      ctx.lineWidth = 208;
                      ctx.strokeStyle = colors[i % colors.length]
                      ctx.arc(0, 0, 104, startAngel, endAngel)
                      ctx.stroke();
                      startAngel = endAngel
                      endAngel += angel
                  }
                  // 添加奖品
                  function loadImg() {

                      const dtd = $.Deferred()
                      let countImg = 0
                      if (awardPic.length) {
                          return dtd.resolve(awardPic);
                      }
                      for (let i = 0; i < num; i++) {
                          const img = new Image()
                          awardPic.push(img)

                          img.src = imgs[i]
                          img.onload = function () {
                              countImg++
                              if (countImg == num) {
                                  dtd.resolve(awardPic);
                              }
                          }
                      }
                      return dtd.promise()
                  }

                  $.when(loadImg()).done(function (awardPic) {

                      startAngel = angel / 2
                      for (let i = 0; i < num; i++) {
                          ctx.save();
                          ctx.rotate(startAngel)
                          ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                          ctx.font = _default.title.font;
                          ctx.fillStyle = _default.title.color
                          ctx.textAlign = "center";
                          ctx.fillText(awardTitle[i], 0, -170);
                          startAngel += angel
                          ctx.restore();
                      }
                  })
                  ctx.restore();
              },
              /**
               * @param angel 旋转角度
               * @param callback 转完后的回调函数
               */
              lottery: function (angel: any, callback: any) {
                  angel = angel || 0
                  angel = 360-angel
                  angel += 720
                  // 基值（减速）
                  const baseStep = 30
                  // 起始滚动速度
                  const baseSpeed = 0.3
                  // 步长
                  let count = 1;
                  const _this = this
                  const timer = setInterval(function () {

                      _this.init(count)
                      if (count == angel) {
                          clearInterval(timer)
                          if (typeof callback == "function") {
                              callback()
                          }
                      }

                      count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                      if (angel - count < 0.5) {
                          count = angel
                      }

                  }, 25)
              }
          }

      }

      //初始化查看奖品链接
      let rewardType=3;
      var wheelSurf: any
      // 初始化装盘数据 正常情况下应该由后台返回
      var initData = {
        "success": true,
        "list": [
          {
            "id": 1,
            "name": "iPhone14Pro",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/f0cf74429b5fee8e3ee845d918b3bf66.png",
            "rank": 1,
            "percent": 15
          },
          {
            "id": 2,
            "name": "Apple AirPods耳机",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/03530381bf0b3c986fb662667842e2da.png",
            "rank": 2,
            "percent": 10
          },
          {
            "id": 3,
            "name": "100元京东购物卡",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/38ee4ce6bacab2e3bb5f817e9b9de135.png",
            "rank": 3,
            "percent": 10
          },
          {
            "id": 4,
            "name": "稻香村月饼礼盒",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/9ff7ba70382352364e54faf448da0876.png",
            "rank": 4,
            "percent": 15
          },
          {
            "id": 5,
            "name": "迷你加湿器",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/3f42e70f6ed56ce2034938d37823c4e3.png",
            "rank": 5,
            "percent": 10
          },
          {
            "id": 6,
            "name": "太空兔挂件",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/ca7189f80e461a20a22411a0ccf12bc9.png",
            "rank": 6,
            "percent": 10
          },
          {
            "id": 7,
            "name": "100元优惠券",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/fa6a14958a04963580718369ea57540e.png",
            "rank": 7,
            "percent": 15
          },
          {
            "id": 8,
            "name": "1888积分",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/fb804f34b04bed2150f0b1a50c2d5dc2.png",
            "rank": 8,
            "percent": 15
          },
        ]
      }

      var list = {}
      var angel = 360 / initData.list.length
      // 格式化成插件需要的奖品列表格式
      for (var i = 0, l = initData.list.length; i < l; i++) {
          list[initData.list[i].rank] = {
              id: initData.list[i].id,
              name: initData.list[i].name,
              image: initData.list[i].image
          }
      }
      var throttle = true;

      onMounted(() => {
        nextTick(() => {
          const token = localStorage.getItem("token");
          if(token){
            // console.log("token2222222")
            //  生成邀请码
            myInvitationImg.value="/M/Server/createInvitationImgSecond?token="+token;
          }
        });


        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()


        $("#start").on('click', function () {

            if(partakeCount.value < 1){
                if(inviteCount.value >= 3){
                    Modal({
                        title: "温馨提示",
                        content: "剩余抽奖次数为0，感谢您的积极参与，敬请期待后续活动~",
                        onClose: () => {
                        },
                    });
                }else{
                    Modal({
                        title: "温馨提示",
                        content: "剩余抽奖次数为0，分享给好友成功注册，可获得更多抽奖机会",
                        onClose: () => {
                        },
                    });
                }
                return false;
            }
            if (isLoginFlag.value == 0){
                Modal({
                    title: "温馨提示",
                    content: "登录后才可以进行抽奖哦~",
                    confirmText:"登录",
                    onConfirm: () => {
                        router.push({
                            name: "InviteRegister",
                            query: {
                                redirect: router.currentRoute.value.fullPath,
                                addType:"1",
                            },
                        });
                    },
                });
                return false;
            }

            var winData: any; // 正常情况下获奖信息应该由后台返回
            axios
                .post("/M/Event/doGetGiftOflLuxury",
                    qs.stringify({
                        eventUniqueMark: 'GIFT_OF_LUXURY'
                    })
              )
              .then((res: any) => {
                if (res.data.success) {
                    //剩余次数扣减
                    partakeCount.value = partakeCount.value -1;

                    winData = res.data.data
                    rewardType=res.data.data.rewardType
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });

      })


      function onClosePrize() {
        showRedPrize.value = false;
        // if(rewardType===1){
        //     router.push({
        //         name: "Intergral"
        //     });
        //
        // }else if(rewardType===2){
        //     router.push({
        //         name: "Coupon"
        //     });
        // }
      }

        if(localStorage.getItem('WX')){
            setTimeout(function () {
                location.reload();
            },1000)
        }
        localStorage.removeItem('WX')
      return {
        inviteCount,
        isLoginFlag,
        partakeCount,
        userId,
        winnerVOS,
        productRms,
        myInvitationImg,
        showRewardadd,
        getAddress,
        closeModal,
        winnerList,
        addressInfo,

        showRedPrize,
        onClosePrize,
        awardImg,
        awardName,
        shareFriends,
        dateFormat
      }
    }
});
</script>


<style lang="scss" scoped>
.sweepstakes_wrap {
    padding: 0.2rem;
    background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/ce1c3ebd8fe8724e20dd23a4398bf4d4.jpg');
    background-size: cover;
    padding-bottom: 50px;
    padding-top: 10rem
}

.sweepstakes_wrap h1 {
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 22px;
}

.start_content {
    background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/7c8f481a93dadf83c518b66729b78e3d.png') no-repeat;
    background-size: 100%;
    position: relative;
}

.start_desc_title {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
    img {
        width: 30%;
    }
}

.sweepstakes_title {
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  color: #666;
    padding-top: 1.5rem;
  padding-bottom: 0;
}

.sweepstakes_title p {
    font-size: 14px;
}

.sweepstakes_title p span {
    font-size: 12px;
    font-weight: 100;
}
.sweepstakes_title h3{
  font-size: 14px;
  color:#ea5146;
  position: absolute;
  top:8px;
  left:73px;
}
.sweepstakes_title textarea {
  width: 100%;
  font-size: 14px;
  padding: 5px;
  border: 1px solid #808080;
}

.sweepstakes_guan {
    display: block;
    margin-top: 5px;
    font-size: 14px;
    // margin-bottom: 10px;
    line-height: 30px;
    font-weight: 500;
}

.sweepstakes_guan input {
  width: 65px;
  height: 23px;
  line-height: 23px;
  outline: none;
  text-align: center;
  font-size: 14px;

  overflow: hidden;
}
.sweepstakes_guan input:-webkit-autofill {
    box-shadow: 0 0 0 1000px #96090F inset;
    -webkit-text-fill-color: #fff;
}

.sweepstakes_title span {
font-size: 0.25rem;
display: inline-block;
}

.sweepstakes_title span a {
    color: #0072bb;
}
.sweepstakes_content {
    text-align: center;
    position: relative;
    //padding: 0.4rem 0;
    padding-top: 0;
}

.Sweepstakes_start {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.75rem;
    /*margin-top: -0.75rem;*/
    margin-top: -1.05rem;
    cursor: pointer;
    width: 1.5rem;
}

.Sweepstakes_dibu {
    width: 100%;
}

.challengeWhellSelect {
    width: 2.3rem;
    height: 25px;
    line-height: 25px;
    background: none;
    font-family: 'Microsoft YaHei';
    font-size: 14px;
}

.challengeWhellTitle {
    text-align: center;
    font-size: 0.28rem;
    line-height: 0.5rem;
}

.Sweepstakes_bottom_content {
    line-height: 0.6rem;
}

    .Sweepstakes_bottom_content p a {
        text-decoration: underline;
        color: #2a2aca;
    }

.Choice {
    width: 100%;
    height: auto;
    margin-top: 0.1rem;
}

label {
    display: block;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
}

.Choice input {
    vertical-align: middle;
    margin-right: 10px;
}

.lottery-text {
    position: absolute;
    left: 11%;
    top: 49.5%;
    width: 80%;
    /* margin-left: -40%; */
    z-index: 20;
    color: #ec3f3f;
    font-size: 0.20rem;
    text-align: center;
}

#winnerIMG {
    width: 1.5rem;
    height: 1.3rem;
    position: absolute;
    top: -1.8rem;
    right: 1.25rem;
}

.start_integral {
    background: url('../assets/img/startGift/gift4.png') no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    height: 286px;
}
.start_product {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.start_pic {
    width: 75px;
    height: 102px;
    img {
        width: 75px;
        height: 75px;
    }
    div {
        font-size: 10px;
        color: #fff;
        text-align: center;
        position: relative;
        top: -4px;
    }
}
.start_pro_more {
    text-align: center;
    padding-bottom: 10px;
}
.sweepstakes_btn {
    margin: 0 auto;
    display: inline-block;
    width: 65%;
    height: 0.9rem;
    background-color: #F8D2AE;
    box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.2);
    border-radius: 45px;
    line-height: 0.9rem;
    text-align: center;
    color: #A50D09 !important;
    font-size: 0.4rem;
    font-weight: bold;
}

.start_rule {
    background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/22de590fc17348a3303ed6723e328708.png') no-repeat;
    background-size: 100%;
    padding: 20px;
    margin-top: -170px;
    position: relative;
}
.start_rule h3{
  font-size: 14px;
  color:#ea5146;
  position: absolute;
  top:3px;
  left:160px;

}

.start_rule_content {
    color: #5c1e08;
    font-size: 12px;
    padding-top: 0.8rem;
}

.start_rule_content p {
    line-height: 22px;
}
.send_products{
  margin-top: 20px;
  height: auto;
  background: url('https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/678dccd253fb1a51c611c6476b45aca2.png');
  background-size: 100%;
  position: relative;
}
.send_products h3{
  font-size: 14px;
  color:#ea5146;
  position: absolute;
  top:5px;
  left:100px;
}
.send_products p{
  text-indent: 2em;
}
.send_products ul{
  width:100%;
  margin-top: 15px;
  padding-bottom: 60px;
}
.send_products ul li{
  width:30%;
  float:left;
  margin-left: 15px;
}
.send_products ul li:nth-child(1){
  margin-left: 0;
}
.send_products ul li img{
  width:100%;
}
.viewport{
  width:95%;
  overflow: hidden;
}
.zhongjiang{
  width:95%;
  margin-left: 10px;
  border:1px solid #fdd499;
  margin-top: 10px;
  height: 130px;
  overflow-y: auto;
}
.viewport::-webkit-scrollbar{
  width:10px;
}
.viewport::-webkit-scrollbar-track{
  background-color: #f1f1f1;
}
.viewport::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块的颜色 */
  border-radius: 5px; /* 滚动条滑块的圆角 */
}
/* 鼠标悬停时滚动条滑块 */
.viewport::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 鼠标悬停时滚动条滑块的颜色 */
}

///* 点击滚动条轨道时的空白区域 */
//.viewport::-webkit-scrollbar-track-piece {
//  background-color: #ddd; /* 点击滚动条轨道时的空白区域颜色 */
//}

/* 触摸设备上的滚动条 */
.viewport::-webkit-scrollbar-thumb:vertical {
  background-color: #666; /* 竖直滚动条滑块的颜色 */
}

.viewport::-webkit-scrollbar-thumb:horizontal {
  background-color: #666; /* 水平滚动条滑块的颜色 */
}

/* 滚动条角落 */
.content::-webkit-scrollbar-corner {
  background-color: #f1f1f1; /* 滚动条角落的颜色 */
}


.zhongjiang li{
  padding-left:15px;
  padding-top:8px;
  font-size: 12px;
}
.zhongjiang li span{
  color:#ea5146;
}
.send_bottom{
  width:95%;
  height: 200px;
  margin:0 auto;
}
.bottom_product{
  width:100%;
  padding-bottom: 50px;
}
.bottom_product li{
  width:45%;
  float:left;
  font-size: 12px;
  margin-top: 20px;

}
.bottom_product li:nth-child(even){
  margin-left: 30px;

}
.bottom_product li p{
  text-align: center;
  line-height: 25px;
  color:#5c1e08;
}
.bottom_product li img{
  width:100%;
}
.see_more{
  display: inline-block;
  background-color: rgb(255, 91, 33);
  border-radius: 0.96rem;
  color: rgb(255, 255, 255);
  width: 120px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.send_proname{
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.modal-backdrop{
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
}
.modal-body{
    background: #fff;
    border-radius: 8px;
    z-index: 99;
    width: 320px;
    height: 416px;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /*padding: 20px 5px;*/
    font-size: 14px;
}
#couponGiveForm {
  height: 280px;
  overflow: auto;
}
.coupon-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin: 0 auto;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top:20px;
}
.coupon_btn{
    color: #fff;
    font-size: 14px;
    margin-top: 7px;
    background: #e00025;
    padding: 4px 15px;
}
.reward_list{
  width:90%;
  margin:0 auto;
  margin-top:15px;
}
.prize_record{
  width:100%;
  height: auto;
  color:#5c1e08;
  border-collapse:collapse;
}
.prize_record th {
  border: 1px solid #e5e5e5;
  height: 25px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  background: #f5f5f5;
}
.prize_record td {
  border: 1px solid #e5e5e5;
  height: 25px;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
}

.reward_address{
  width:90%;
  margin:0 auto;
  margin-top:15px;
}
.modal-close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: 0;
    border-radius: 50%;
    border: 0;
}
</style>
