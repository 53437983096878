
  import {defineComponent, ref, onMounted, inject, nextTick} from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import RedPrize from "./RedPrize.vue";
import { useRouter } from "vue-router";
import qs from "qs";
import dateFormat from "../utils/dateFormat";

export default defineComponent({
    name: "sendGiftOflLuxury",
    components: {
      RedPrize
    },
    setup(){
      const router = useRouter();
      const showRedPrize = ref(false);
      const showRewardadd = ref(false);
      const awardName = ref();
      const awardImg = ref();
      const inviteCount = ref(0);
      const isLoginFlag = ref(0);
      const partakeCount = ref(0);
      const userId = ref(0);
      const winnerVOS = ref([]);
      const productRms = ref([]);
      const myInvitationImg = ref('');

      const winnerList = ref([]);//中奖记录
      const addressInfo = ref(null);//地址信息


      // 获取相关信息
      function getGiftOflLuxuryInfo(eventUniqueMark: any) {
        axios
            .get("/M/Event/getGiftOflLuxuryInfo" , { params: { eventUniqueMark: eventUniqueMark } })
            .then((res): void => {
              const data = res.data.obj;
              inviteCount.value = data.inviteCount;
              isLoginFlag.value = data.isLoginFlag;
              partakeCount.value = data.partakeCount;
              userId.value = data.userId;
              winnerVOS.value = data.winnerVOS;
              productRms.value = data.productRms;

            })
            .catch((err) => {
              console.log(err);
            });
      }

      getGiftOflLuxuryInfo('GIFT_OF_LUXURY');

      //分享好友
      function shareFriends() {
        let confirmText ="确定";
        let optionControl =1;
        let content =  `
                  <div>长按我，转发给朋友</div>
                  <div style="height: 140px;">
                    <div style="text-align: center;width: 98%;">
                      <img width="160px" src="`+myInvitationImg.value+`"  alt="">
                    </div>
                  </div>
                  `;
        if(isLoginFlag.value == 0){
          confirmText="登录";
          optionControl=0;
          content = "登录后即可邀请，立刻前往";

        }
        Modal({
            title: "温馨提示",
            confirmText:confirmText,
            optionControl:optionControl,
            content:content,
            dangerouslyUseHTMLString: true,
            onConfirm: () => {
              router.push({
                name: "InviteRegister",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                    addType:"1",
                },
              });
            },
            onClose: () => {
              console.log('1')
            },
          });
      }

    // 中奖信息
    function getAddress() {
        if(isLoginFlag.value == 0){
            Modal({
                title: "温馨提示",
                content: "请先执行登录",
                confirmText:"登录",
                onConfirm: () => {
                    router.push({
                        name: "InviteRegister",
                        query: {
                            redirect: router.currentRoute.value.fullPath,
                            addType:"1",
                        },
                    });
                },
            });
            return false;
        }

        showRewardadd.value = true;
        axios
            .get("/M/Event/rewardWinnerInfo")
            .then((res): void => {
                const data = res.data.obj;
                addressInfo.value = data.addressInfo;
                winnerList.value = data.winnerList;
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // 关闭弹框
    function closeModal() {
        showRewardadd.value = false;
    }

      ($.fn as any).WheelSurf = function (options: any) {
          const _default = {
              outerCircle: {
                  color: '#df1e15'
              },
              innerCircle: {
                  color: '#f4ad26'
              },
              dots: ['#fbf0a9', '#fbb936'],
              disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'],
              title: {
                  color: '#5c1e08',
                  font: '19px Arial'
              }
          }

          $.extend(_default,options)
          // 画布中心移动到canvas中心

          /* eslint-disable */
          const _this = this[0],
              width = _this.width,
              height = _this.height,
              ctx = _this.getContext("2d"),
              imgs: any = [],
              awardTitle: any = [],
              awardPic: any = []
          for (const item in (_default as any).list) {
              awardTitle.push((_default as any).list[item].name)
              imgs.push((_default as any).list[item].image)
          }
          const num = imgs.length
          // 圆心
          const x = width / 2
          const y = height / 2
          ctx.translate(x, y)

          return {
              init: function (angelTo: any) {
                  angelTo = angelTo || 0;

                  ctx.clearRect(-this.width, -this.height, this.width, this.height);

                  // 平分角度
                  const angel = (2 * Math.PI / 360) * (360 / num);
                  let startAngel = 2 * Math.PI / 360 * (-90)
                  let endAngel = 2 * Math.PI / 360 * (-90) + angel

                  // 旋转画布
                  ctx.save()
                  ctx.rotate(angelTo * Math.PI / 180);
                  // 画外圆
                  ctx.beginPath();
                  ctx.lineWidth = 25;
                  ctx.strokeStyle = _default.outerCircle.color;
                  ctx.arc(0, 0, 243, 0, 2 * Math.PI)
                  ctx.stroke();
                  // 画里圆
                  ctx.beginPath();
                  ctx.lineWidth = 23;
                  ctx.strokeStyle = _default.innerCircle.color;
                  ctx.arc(0, 0, 218, 0, 2 * Math.PI)
                  ctx.stroke();

                  // 装饰点
                  const dotColor = _default.dots
                  for (let i = 0; i < 12; i++) {
                      // 装饰点 圆心 坐标计算
                      ctx.beginPath();
                      const radius = 230;
                      const xr = radius * Math.cos(startAngel)
                      const yr = radius * Math.sin(startAngel)

                      ctx.fillStyle = dotColor[i % dotColor.length]
                      ctx.arc(xr, yr, 11, 0, 2 * Math.PI)
                      ctx.fill()

                      startAngel += (2 * Math.PI / 360) * (360 / 12);

                  }
                  // 画里转盘
                  const colors = _default.disk
                  for (let i = 0; i < num; i++) {
                      ctx.beginPath();
                      ctx.lineWidth = 208;
                      ctx.strokeStyle = colors[i % colors.length]
                      ctx.arc(0, 0, 104, startAngel, endAngel)
                      ctx.stroke();
                      startAngel = endAngel
                      endAngel += angel
                  }
                  // 添加奖品
                  function loadImg() {

                      const dtd = $.Deferred()
                      let countImg = 0
                      if (awardPic.length) {
                          return dtd.resolve(awardPic);
                      }
                      for (let i = 0; i < num; i++) {
                          const img = new Image()
                          awardPic.push(img)

                          img.src = imgs[i]
                          img.onload = function () {
                              countImg++
                              if (countImg == num) {
                                  dtd.resolve(awardPic);
                              }
                          }
                      }
                      return dtd.promise()
                  }

                  $.when(loadImg()).done(function (awardPic) {

                      startAngel = angel / 2
                      for (let i = 0; i < num; i++) {
                          ctx.save();
                          ctx.rotate(startAngel)
                          ctx.drawImage(awardPic[i], -36, -23 - 130, 70, 70);
                          ctx.font = _default.title.font;
                          ctx.fillStyle = _default.title.color
                          ctx.textAlign = "center";
                          ctx.fillText(awardTitle[i], 0, -170);
                          startAngel += angel
                          ctx.restore();
                      }
                  })
                  ctx.restore();
              },
              /**
               * @param angel 旋转角度
               * @param callback 转完后的回调函数
               */
              lottery: function (angel: any, callback: any) {
                  angel = angel || 0
                  angel = 360-angel
                  angel += 720
                  // 基值（减速）
                  const baseStep = 30
                  // 起始滚动速度
                  const baseSpeed = 0.3
                  // 步长
                  let count = 1;
                  const _this = this
                  const timer = setInterval(function () {

                      _this.init(count)
                      if (count == angel) {
                          clearInterval(timer)
                          if (typeof callback == "function") {
                              callback()
                          }
                      }

                      count = count + baseStep * (((angel - count) / angel) > baseSpeed ? baseSpeed : ((angel - count) / angel))
                      if (angel - count < 0.5) {
                          count = angel
                      }

                  }, 25)
              }
          }

      }

      //初始化查看奖品链接
      let rewardType=3;
      var wheelSurf: any
      // 初始化装盘数据 正常情况下应该由后台返回
      var initData = {
        "success": true,
        "list": [
          {
            "id": 1,
            "name": "iPhone14Pro",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/f0cf74429b5fee8e3ee845d918b3bf66.png",
            "rank": 1,
            "percent": 15
          },
          {
            "id": 2,
            "name": "Apple AirPods耳机",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/03530381bf0b3c986fb662667842e2da.png",
            "rank": 2,
            "percent": 10
          },
          {
            "id": 3,
            "name": "100元京东购物卡",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/38ee4ce6bacab2e3bb5f817e9b9de135.png",
            "rank": 3,
            "percent": 10
          },
          {
            "id": 4,
            "name": "稻香村月饼礼盒",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/9ff7ba70382352364e54faf448da0876.png",
            "rank": 4,
            "percent": 15
          },
          {
            "id": 5,
            "name": "迷你加湿器",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/3f42e70f6ed56ce2034938d37823c4e3.png",
            "rank": 5,
            "percent": 10
          },
          {
            "id": 6,
            "name": "太空兔挂件",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/ca7189f80e461a20a22411a0ccf12bc9.png",
            "rank": 6,
            "percent": 10
          },
          {
            "id": 7,
            "name": "100元优惠券",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/fa6a14958a04963580718369ea57540e.png",
            "rank": 7,
            "percent": 15
          },
          {
            "id": 8,
            "name": "1888积分",
            "image": "https://shijiaoke.oss-cn-beijing.aliyuncs.com/Event/fb804f34b04bed2150f0b1a50c2d5dc2.png",
            "rank": 8,
            "percent": 15
          },
        ]
      }

      var list = {}
      var angel = 360 / initData.list.length
      // 格式化成插件需要的奖品列表格式
      for (var i = 0, l = initData.list.length; i < l; i++) {
          list[initData.list[i].rank] = {
              id: initData.list[i].id,
              name: initData.list[i].name,
              image: initData.list[i].image
          }
      }
      var throttle = true;

      onMounted(() => {
        nextTick(() => {
          const token = localStorage.getItem("token");
          if(token){
            // console.log("token2222222")
            //  生成邀请码
            myInvitationImg.value="/M/Server/createInvitationImgSecond?token="+token;
          }
        });


        // 定义转盘奖品
        wheelSurf = ($('#myCanvas') as any).WheelSurf({
            list: list, // 奖品 列表，(必填)
            outerCircle: {
                color: '#e74b44' // 外圈颜色(可选)
            },
            innerCircle: {
                color: '#f4ad26' // 里圈颜色(可选)
            },
            //dots: ['#fbf0a9', '#fbb936'], // 装饰点颜色(可选)
            dots: ['#fff7af', '#fafffe'],
            //disk: ['#ffb933', '#ffe8b5', '#ffb933', '#ffd57c', '#ffb933', '#ffe8b5', '#ffd57c'], //中心奖盘的颜色，默认7彩(可选)
            disk: ['#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0', '#ffeaa7', '#fcf9f0'],
            title: {
                color: '#5c1e08',
                font: '16px Arial'
            } // 奖品标题样式(可选)
        })
        // 初始化转盘
        wheelSurf.init()


        $("#start").on('click', function () {

            if(partakeCount.value < 1){
                if(inviteCount.value >= 3){
                    Modal({
                        title: "温馨提示",
                        content: "剩余抽奖次数为0，感谢您的积极参与，敬请期待后续活动~",
                        onClose: () => {
                        },
                    });
                }else{
                    Modal({
                        title: "温馨提示",
                        content: "剩余抽奖次数为0，分享给好友成功注册，可获得更多抽奖机会",
                        onClose: () => {
                        },
                    });
                }
                return false;
            }
            if (isLoginFlag.value == 0){
                Modal({
                    title: "温馨提示",
                    content: "登录后才可以进行抽奖哦~",
                    confirmText:"登录",
                    onConfirm: () => {
                        router.push({
                            name: "InviteRegister",
                            query: {
                                redirect: router.currentRoute.value.fullPath,
                                addType:"1",
                            },
                        });
                    },
                });
                return false;
            }

            var winData: any; // 正常情况下获奖信息应该由后台返回
            axios
                .post("/M/Event/doGetGiftOflLuxury",
                    qs.stringify({
                        eventUniqueMark: 'GIFT_OF_LUXURY'
                    })
              )
              .then((res: any) => {
                if (res.data.success) {
                    //剩余次数扣减
                    partakeCount.value = partakeCount.value -1;

                    winData = res.data.data
                    rewardType=res.data.data.rewardType
                    throttle = false;
                    var count = 0
                    // 计算奖品角度
                    for (var key in list) {
                        if (list.hasOwnProperty(key)) {
                            if (winData.rewardNum == list[key].id) {
                                winData.image = list[key].image
                                break;
                            }
                            count++
                        }
                    }

                    // 转盘抽奖，
                    wheelSurf.lottery((count * angel + angel / 2), function () {
                        awardName.value = winData.rewardName
                        awardImg.value = winData.image
                        showRedPrize.value = true
                        throttle = true;
                    })

                } else {
                    Toast({
                      title: res.data.msg,
                      type: "error",
                      duration: 2000,
                    });
                }
              })
              .catch((err) => {
                Toast({
                      title: '执行错误，请联系管理员！',
                      type: "error",
                    });
              });
        });

      })


      function onClosePrize() {
        showRedPrize.value = false;
        // if(rewardType===1){
        //     router.push({
        //         name: "Intergral"
        //     });
        //
        // }else if(rewardType===2){
        //     router.push({
        //         name: "Coupon"
        //     });
        // }
      }

        if(localStorage.getItem('WX')){
            setTimeout(function () {
                location.reload();
            },1000)
        }
        localStorage.removeItem('WX')
      return {
        inviteCount,
        isLoginFlag,
        partakeCount,
        userId,
        winnerVOS,
        productRms,
        myInvitationImg,
        showRewardadd,
        getAddress,
        closeModal,
        winnerList,
        addressInfo,

        showRedPrize,
        onClosePrize,
        awardImg,
        awardName,
        shareFriends,
        dateFormat
      }
    }
});
